import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";

import { getAlertConfirm } from "../../store/AlertDialog/alertDialogSlice";
import { DialogHeader } from "../../components/Dashboard/Common/dialogHeader";

const AlertDialog = (props) => {
  const { title, message, onClose } = props;
  const dispatch = useDispatch();

  const confirmation = async (value) => {
    const isConfirm = await dispatch(getAlertConfirm(value));
    if (value === false) {
      onClose(false);
    }

    return isConfirm;
  };

  const {
    onCancel = () => confirmation(false),
    onConfirm = () => confirmation(true),
  } = props;
  return (
    <Dialog open={true} maxWidth="sm" fullWidth onClose={onCancel}>
      <DialogHeader onClose={onCancel} title={title ? title : "Confirmation"} />
      <DialogContent>
        <Typography>{message ? message : ""}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
