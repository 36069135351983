import { createSlice } from "@reduxjs/toolkit";
import { ManufactureService } from "../../services/manufactureService/index";
import history from "../../utils/history";
import { showMessage } from "../Revalu/notificationSlice";

export const list = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return ManufactureService.list(data)
    .then((res) => {
      dispatch(setManufacturerData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const changeBlockedStatus = (data) => async (dispatch) => {
  return ManufactureService.changeBlockedStatus(data)
    .then((res) => {
      if (Boolean(res?.modifiedCount)) {
        dispatch(modifyRecord(data));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const changeFeaturedManufacturerStatus = (data) => async (dispatch) => {
  return ManufactureService.updateFeaturedManufacturerStatus(data)
    .then((res) => {
      if (Boolean(res?.modifiedCount)) {
        dispatch(modifyRecord(data));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getById = (id) => async (dispatch) => {
  return ManufactureService.getById(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const create = (data) => async (dispatch) => {
  return ManufactureService.create(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const update = (id, data) => async (dispatch) => {
  return ManufactureService.update(id, data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const updateList = (data) => async (dispatch) => {
  return ManufactureService.list(data)
    .then((res) => {
      dispatch(setManufacturerData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      return true;
    });
};

export const deleteById = (id) => async (dispatch) => {
  return ManufactureService.deleteById(id)
    .then((res) => {
      if (Boolean(res?.deletedCount)) {
        dispatch(removeRecord(id));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getManufacturerContacts = (id) => async (dispatch) => {
  return ManufactureService.getManufactureContact(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const deleteManufactureContact = (data) => async (dispatch) => {
  return ManufactureService.deleteManufactureContact(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getManufactureSuppliers = (id) => async (dispatch) => {
  return ManufactureService.getManufactureSuppliers(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const deleteManufactureSupplier = (data) => async (dispatch) => {
  return ManufactureService.deleteManufactureSupplier(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const exportData = (params) => async (dispatch) => {
  dispatch(setExportLoading(true));
  return ManufactureService.export(params)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setExportLoading(false));
    });
};
export const getExportProcessStatus = () => async (dispatch) => {
  return ManufactureService.getExportProcessStatus()
    .then((res) => {
      dispatch(setExportProcessStatus(res));
      return res;
    })
    .catch((errors) => {
      dispatch(setExportProcessStatus(null));
      return errors;
    });
};
export const startExportProcess = () => async (dispatch) => {
  dispatch(setExportProcessLoading(true));
  return ManufactureService.startExportProcess()
    .then(async (res) => {
      await dispatch(getExportProcessStatus());
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setExportProcessLoading(false));
    });
};
export const qualityCheck = (id) => (dispatch) => {
  return ManufactureService.qualityCheck(id)
    .then((res) => {
      dispatch(
        showMessage({ message: res ? res : "Successfuly Quality Check" }),
        window.location.reload(),
      );
      window.location.reload();
      return true;
    })
    .catch((errors) => {
      dispatch(
        showMessage({ message: errors.data || "Error", variant: "error" }),
      );
      return errors;
    });
};
export const statusUpdate = (data, id) => async (dispatch) => {
  return ManufactureService.statusUpdate(data, id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getElements = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return ManufactureService.getElements(data)
    .then((res) => {
      dispatch(setElements(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const getBroaderCategories = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  return ManufactureService.getBroaderCategories(id)
    .then((res) => {
      dispatch(setBroaderCategories(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const getSubCategory = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  return ManufactureService.getSubCategory(id)
    .then((res) => {
      dispatch(setSubCategory(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const getCategoryHierarchyID = (data) => async (dispatch) => {
  return ManufactureService.getCategoryHierarchyID(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const submitCategoryHierarchy = (data) => async (dispatch) => {
  return ManufactureService.submitCategoryHierarchy(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getManufacturerAttributes = () => async (dispatch) => {
  return ManufactureService.getManufacturerAttributes()
    .then((res) => {
      dispatch(setManufacturerAttributes(res));
    })
    .catch((errors) => {})
    .finally(() => {
      return true;
    });
};

const initialState = {
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
    selected: null,
  },
  originalData: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  exportLoading: false,
  exportProcessLoading: false,
  searchValue: "",
  exportProcessStatus: null,
  elementsData: "",
  broaderCategoriesData: "",
  subCategoryData: "",
  getCategoryHierarchyData: "",
  selected: null,
  manufacturerAttributes: {},
};

const manufactureSlice = createSlice({
  name: "mappedEPD/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setManufacturerData: (state, action) => {
      state.data = action.payload;
      state.originalData = action.payload;
    },
    modifyRecord: (state, action) => {
      const { id, blocked } = action.payload;
      const index = state.data.content.findIndex(
        (f) => f["Data Source ID"] === id,
      );
      if (index > -1) {
        state.data.content[index].blocked = blocked;
        state.originalData.content[index].blocked = blocked;
      }
    },
    removeRecord: (state, action) => {
      const id = action.payload;
      const index = state.data.content.findIndex(
        (f) => f["Data Source ID"] === id,
      );
      if (index > -1) {
        state.data.content.splice(index, 1);
        state.originalData.content.splice(index, 1);
      }
    },
    setExportProcessStatus: (state, action) => {
      state.exportProcessStatus = action.payload;
    },
    setExportLoading: (state, action) => {
      state.exportLoading = action.payload;
    },
    setExportProcessLoading: (state, action) => {
      state.exportProcessLoading = action.payload;
    },

    searchData: (state, action) => {
      const { search } = action.payload;
      state.searchValue = search;
    },
    setElements: (state, action) => {
      state.elementsData = action.payload;
    },
    setBroaderCategories: (state, action) => {
      state.broaderCategoriesData = action.payload;
    },
    setSubCategory: (state, action) => {
      state.subCategoryData = action.payload;
    },
    setManufacturerAttributes: (state, action) => {
      state.manufacturerAttributes = {
        attributes: action.payload || [],
      };
    },
    setCategoryHierarchyData: (state, action) => {
      state.getCategoryHierarchyData = action.payload;
    },
    setSelected: (state, action) => {
      const { id } = action.payload;
      const index = state.data.content.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.selected = state.data.content[index];
      }
    },
    resetSelected: (state, action) => {
      state.selected = null;
    },
  },
});

export const {
  setLoading,
  setManufacturerData,
  modifyRecord,
  setExportLoading,
  setExportProcessStatus,
  setExportProcessLoading,
  searchData,
  setElements,
  setBroaderCategories,
  setSubCategory,
  setManufacturerAttributes,
  removeRecord,
  setSelected,
  resetSelected,
} = manufactureSlice.actions;

export default manufactureSlice.reducer;
