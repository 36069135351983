/**
 * Dialog which enable to organize the conctacts for a manufacturer
 */

import { Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { DialogHeader } from "../Common/dialogHeader";
import MUIDataTable from "mui-datatables";
import {
  deleteManufactureContact,
  getManufacturerContacts,
} from "../../../store/Manufactures/manufactureSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import ContactDetails from "./contactDetails";

const ContactsDialog = (props) => {
  const dispatch = useDispatch();
  const { id, setOpen, open } = props;

  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const refresh = () => setUpdate((prev) => setUpdate(!prev));

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true);
      const res = await dispatch(getManufacturerContacts(id));
      setLoading(false);
      if (res.length) {
        setContacts(res);
      } else {
        setLoading(false);
        dispatch(showMessage({ message: "No contacts Found" }));
      }
    };
    fetchContacts();
  }, [id, dispatch, update]);

  const deleteContact = async (values) => {
    const data = {
      manufacturerId: id,
      contactManufacturerId: values?.id,
    };
    setLoading(true);
    const res = await dispatch(deleteManufactureContact(data));
    setLoading(false);
    if (res) {
      refresh();
      dispatch(showMessage({ message: "Unlink Successfully" }));
    } else {
      setLoading(false);
      dispatch(showMessage({ message: "No Contacts Found" }));
    }
  };

  const getParsedData = () => {
    return contacts?.map((m) => [
      m.contactName,
      m?.email,
      m?.telephoneNo,
      m?.country,
      m?.city,
      m?.street,
      m?.zipCode,
      {
        id: m?.id,
      },
    ]);
  };

  const headers = [
    { name: "Name", options: { sort: true } },
    { name: "Email", options: { sort: false } },
    { name: "TelephoneNo", options: { sort: false } },
    { name: "Country", options: { sort: false } },
    { name: "City", options: { sort: false } },
    { name: "Street", options: { sort: false } },
    { name: "Zip Code", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => deleteContact(value)}>
              Unlink
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    search: false,
    pagination: false,
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose} scroll="body">
      <DialogHeader onClose={onClose} title={"Manufacturer Contacts"} />
      <DialogContent>
        <ContactDetails id={id} refresh={refresh} />
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <div>
          <div>
            <MUIDataTable
              title=""
              columns={headers}
              data={getParsedData()}
              options={{ ...options }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ContactsDialog;
