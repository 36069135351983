import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Button, Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { styles } from "../../../styles/Dashboard/data";
import { Autocomplete } from "@mui/material";
import { setLoading } from "../../../store/Manufactures/manufactureSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { linking, list } from "../../../store/Contact/contactSlice";

const ContactDetails = (props) => {
  const dispatch = useDispatch();
  const { id, classes, refresh } = props;
  const [contactList, setContactList] = useState("");
  const { data: contactDataList, loading } = useSelector(
    ({ contacts }) => contacts.slice,
  );

  useEffect(() => {
    // Only request contacts if the size is less than 20.000
    if (contactDataList.size !== 20000) {
      dispatch(list({ pageNo: 0, pageSize: 20000, searchValue: "" }));
    }
  }, [id, dispatch]);

  const getContactName = () => {
    const names = contactDataList.content.map((m) => m?.contactName);
    setContactList(names);
  };

  useEffect(() => {
    getContactName();
  }, [contactDataList]);

  const useStyles = makeStyles((theme) => ({
    wrapper: {
      padding: 22,
      boxShadow: "0px 2px 5px #343D4F08",
      borderRadius: 12,
      "& .title": {
        fontSize: 17,
        fontWeight: 500,
        color: theme.palette.primary.fontColor,
      },
    },
    formWrapper: {
      marginBottom: 13,
      "& .textfield": {
        border: "1px solid " + theme.palette.drawer.border,
        borderRadius: 13,
        background: theme.palette.drawer.textFieldBg,
        "& input": {
          padding: "7px 18px",
          height: 11,
        },
      },
    },
    label: {
      color: theme.palette.primary.fontColor,
      fontWeight: 500,
      fontSize: 16,
      display: "block",
      marginBottom: 5,
    },
    submitButton: {
      marginLeft: "auto",
      display: "block",
      marginTop: 13,
      "& span": {
        padding: 0,
      },
    },
  }));

  const schema = yup.object().shape({
    field: yup.string(),
    contactId: yup.string(),
  });

  const defaultValues = {
    field: "",
    contactId: "",
  };

  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const getIdFromManufactureName = (name) => {
    const currentManufacturer = contactDataList?.content.filter(
      (f) => f.contactName === name,
    );
    return currentManufacturer[0]?.id;
  };

  const handleChangeManufacturerAttribute = (e, name) => {
    setValue("field", name);
    const itemValue = getIdFromManufactureName(name);
    setValue("contactId", itemValue);
  };

  const onResponse = (res) => {
    const { data, status, description } = res;
    // if (Boolean(data) && Number(status) === 200) {
    //   if (data?.[0] && data?.[0]["errorMessgae"]) {
    //     dispatch(
    //       showMessage({ message: data?.[0]["errorMessgae"], variant: "error" }),
    //     )
    //   } else {
    //     dispatch(showMessage({ message: "Successfully Linked" }))
    //   }
    //   reset()
    //   onClose()
    // } else if (Number(status) === 400) {
    //   if (
    //     data &&
    //     data["database exception"]
    //       ?.toString()
    //       ?.toLowerCase()
    //       ?.indexOf("duplicate") > -1
    //   ) {
    //     dispatch(
    //       showMessage({
    //         message: "Manufacturer already linked",
    //         variant: "error",
    //       }),
    //     )
    //   }
    // }
    dispatch(showMessage({ message: "Successfully Linked" }));
    dispatch(setLoading(false));
    refresh();
  };

  const onSubmit = async (values) => {
    const contactId = values?.contactId;
    const res = await dispatch(
      linking({
        manufacturerId: id,
        contactManufacturerId: contactId,
      }),
    );
    onResponse(res);
  };

  return (
    <div className={classes.pageWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.formWrapper}>
              <Autocomplete
                onChange={handleChangeManufacturerAttribute}
                fullWidth
                // disablePortal
                id="fieldName"
                options={contactList}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    {...params}
                    label="Contacts"
                  />
                )}
              />
            </div>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: "10px" }}
            disabled={!isValid}
            className={classes.submitButton}>
            {loading ? "Linking..." : "Link"}
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default withStyles(styles)(ContactDetails);
